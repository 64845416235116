/**
 * Kushki Info file
 */
import { KushkiInfo } from "@kushki/js/lib/types/kushki_info";
import { PlatformCodeEnum } from "@kushki/js/lib/infrastructure/PlatformCodeEnum";
import { SourceEnum } from "./shared/infrastructure/SourceEnum";

export class KInfo {
  public static kushkiInfo: KushkiInfo;

  public static buildKushkiInfo(source: string | undefined): KushkiInfo {
    let platformId = PlatformCodeEnum.WEBCHECKOUT;

    if (source && source === SourceEnum.SHOPIFY)
      platformId = PlatformCodeEnum.SHOPIFY;

    if (source && source === SourceEnum.EXPRESS_CHECKOUT)
      platformId = PlatformCodeEnum.EXPRESS_CHECKOUT;

    return {
      platformId,
    };
  }
}
